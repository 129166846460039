<template>
  <v-container>
    <v-row>
      <!-- Folders List -->
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            Resource Folders
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="createFolderDialog = true">
              <v-icon left>mdi-folder-plus</v-icon>Create Folder
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!-- Display folders as a list with selection -->
            <v-list two-line>
              <v-subheader>Available Folders</v-subheader>
              <v-list-item-group  active-class="primary--text">
                <v-list-item v-for="folder in folders" :key="folder.id" @click="selectFolder(folder.id)">
                  <v-list-item-content>
                    <v-list-item-title v-text="folder.name"></v-list-item-title>
                    <v-list-item-subtitle v-text="folder.description"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.stop="deleteFolder(folder.id)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Exercise JSON Upload Section -->
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            Upload Exercises
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input 
              v-model="jsonFile" 
              label="Upload Exercises JSON" 
              accept=".json" 
              @change="handleJsonUpload" 
              prepend-icon="mdi-file-upload-outline"
              :disabled="!selectedFolderId"
            ></v-file-input>

            <v-alert type="info" v-if="!selectedFolderId">
              Please select a folder before uploading exercises.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create Folder Dialog -->
    <v-dialog v-model="createFolderDialog" max-width="500px">
      <v-card>
        <v-card-title>Create New Folder</v-card-title>
        <v-card-text>
          <v-text-field label="Folder Name" v-model="newFolderName"></v-text-field>
          <v-textarea label="Folder Description" v-model="newFolderDescription"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveFolder">Create Folder</v-btn>
          <v-btn @click="createFolderDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFirestore, collection, addDoc, deleteDoc, getDocs, doc } from "firebase/firestore";

export default {
  data() {
    return {
      jsonFile: null,             // Holds the uploaded JSON file
      selectedFolderId: null,     // Selected folder ID for uploading exercises
      folders: [],                // List of folders for selection and display
      createFolderDialog: false,  // Dialog visibility for creating new folders
      newFolderName: "",          // New folder name input
      newFolderDescription: "",   // New folder description input
    };
  },
  
  async created() {
    await this.fetchFolders();  // Load folders on component creation
  },

  methods: {
    // Fetch the list of folders from Firestore
    async fetchFolders() {
      const db = getFirestore();
      const folderSnapshot = await getDocs(collection(db, "resource_folders"));
      this.folders = folderSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description
      }));
    },

    // Select a folder for uploading exercises
    selectFolder(folderId) {
      this.selectedFolderId = folderId;
    },

    // Handle JSON file upload and parse the data
    handleJsonUpload(event) {
      const file = this.jsonFile;
      
      if (file) {
        // Read the JSON file
        const reader = new FileReader();
        reader.onload = (e) => {
          const jsonData = JSON.parse(e.target.result);
          this.uploadExercisesToFolder(jsonData);
        };
        reader.readAsText(file);
      }
    },

    // Upload parsed exercises to the selected folder in Firestore
    async uploadExercisesToFolder(exercises) {
      if (!this.selectedFolderId) {
        this.$notify({
          group: "feedback",
          title: "Error",
          text: "Please select a folder before uploading.",
          type: "error"
        });
        return;
      }

      const db = getFirestore();

      for (const exercise of exercises) {
        // Add each exercise to the 'exercises' subcollection inside the selected folder
        await addDoc(collection(db, `resource_folders/${this.selectedFolderId}/exercises`), exercise);
      }

      this.$notify({
        group: "feedback",
        title: "Exercises Uploaded",
        text: "All exercises have been successfully uploaded.",
        type: "success"
      });
    },

    // Save a new folder to Firestore
    async saveFolder() {
      const db = getFirestore();
      const folderRef = await addDoc(collection(db, 'resource_folders'), {
        name: this.newFolderName,
        description: this.newFolderDescription,
      });

      // Add the new folder to the local list and close the dialog
      this.folders.push({ id: folderRef.id, name: this.newFolderName, description: this.newFolderDescription });
      this.newFolderName = "";
      this.newFolderDescription = "";
      this.createFolderDialog = false;

      this.$notify({
        group: "feedback",
        title: "Folder Created",
        text: `Folder "${this.newFolderName}" was created successfully.`,
        type: "success"
      });
    },

    // Delete a folder from Firestore
    async deleteFolder(folderId) {
      const db = getFirestore();
      await deleteDoc(doc(db, "resource_folders", folderId));

      // Remove folder from the local list
      this.folders = this.folders.filter(folder => folder.id !== folderId);

      this.$notify({
        group: "feedback",
        title: "Folder Deleted",
        text: "Folder has been successfully deleted.",
        type: "success"
      });
    },
  }
};
</script>

<style scoped>
</style>
